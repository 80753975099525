import Cookies from "js-cookie";
import { reportKlaviyoContext } from "./klaviyo.js";

const performForEach = (collection, handler) => {
  Array.from(collection).forEach((item, index) => handler(item, index));
};

const scrollElementToOffset = (element, offset = 0) => {
  const { top } = element.getBoundingClientRect();
  const offsetPosition = top + window.pageYOffset - offset;
  window.scrollTo({ top: offsetPosition, behavior: "smooth" });
};

const installPlayVideoButtons = () => {
  // install handler on play link in video thumbnail
  const playVideoElems = document.getElementsByClassName("js-play-video");
  Array.from(playVideoElems).forEach((trigger) => {
    trigger.addEventListener("click", (e) => {
      e.preventDefault();
      document.getElementById("reverse-gallery-video").src += "?autoplay=1";
    });
  });
};

const updateTopBarVisibility = () => {
  const cookieValue = Cookies.get("hide_top_bar", false);
  const topBar = document.getElementById("js-top-bar");
  if (topBar && cookieValue) {
    topBar.classList.add("d-none");
  }
};

const installTopBarClose = () => {
  const closeButton = document.getElementById("js-top-bar-close");
  if (closeButton) {
    closeButton.addEventListener("click", () => {
      Cookies.set("hide_top_bar", 1, { expires: 1 });
      updateTopBarVisibility();
    });
  }
};

const installTopMenuHandlers = () => {
  const topMenuHandlers = document.getElementsByClassName("js-has-dropdown");
  Array.from(topMenuHandlers).forEach((menuItem) => {
    menuItem.addEventListener("mouseenter", (evt) => {
      const dropdownMenuId = evt.target.dataset.target;
      const menu = document.getElementById(dropdownMenuId);
      menu.classList.add("show");
    });
    menuItem.addEventListener("mouseleave", (evt) => {
      const dropdownMenuId = evt.target.dataset.target;
      const menu = document.getElementById(dropdownMenuId);
      if (
        evt.relatedTarget.id !== dropdownMenuId &&
        !evt.relatedTarget.classList.contains("dropdown-item")
      ) {
        menu.classList.remove("show");
      }
    });
    menuItem.addEventListener("touchend", (evt) => {
      evt.preventDefault();
      let dropdownMenuId = evt.target.dataset.target;
      if (!dropdownMenuId) {
        dropdownMenuId = evt.target.parentNode.dataset.target;
      }
      const menu = document.getElementById(dropdownMenuId);
      menu.classList.toggle("show");
    });
    const menuId = menuItem.dataset.target;
    const menu = document.getElementById(menuId);
    if (menu) {
      menu.addEventListener("mouseleave", () => {
        menu.classList.remove("show");
      });
    }
  });
};

const findParentWithTagName = (e, tagName) => {
  let current = e;
  while (current) {
    if (current.tagName.toLowerCase() !== tagName.toLowerCase()) {
      current = current.parentNode;
    } else {
      return current;
    }
  }
  return null;
};

const installProductClickHandlers = () => {
  const productLinks = document.getElementsByClassName("js-product-click");
  Array.from(productLinks).forEach((link) => {
    link.addEventListener("click", (evt) => {
      const linkTarget = findParentWithTagName(evt.target, "a");
      if (!linkTarget) {
        console.error("no link target");
        return;
      }
      if (window.google_tag_manager && window.productListData) {
        if (!evt.ctrlKey && !evt.metaKey) {
          evt.preventDefault();
        }
        const { feedId } = linkTarget.dataset;
        let foundItem = null;
        if (feedId && window.productListData) {
          foundItem = window.productListData.find((p) => p.item_id === feedId);
        }

        console.log("select_item:", foundItem, feedId, window.productListData);

        if (foundItem) {
          const clickData = {
            event: "select_item",
            ecommerce: {
              items: [foundItem],
            },
            eventCallback: () => {
              if (!evt.ctrlKey && !evt.metaKey) {
                window.location = linkTarget.href;
              }
            },
            eventTimeout: 2000,
          };
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push(clickData);
          // console.log("clickData pushed: ", clickData);
        }
      } else {
        window.location = linkTarget.href;
      }
    });
  });
};

const showMenu = (menuItem) => {
  performForEach(document.getElementsByClassName("submenu"), (elem) =>
    elem.classList.add("active"),
  );
  performForEach(document.getElementsByTagName("body"), (elem) =>
    elem.classList.add("active"),
  );
  menuItem.classList.add("active");
};

let activatedOnEnter = false;

const installMenuHandlers = () => {
  performForEach(
    document.getElementsByClassName("js-has-submenu"),
    (menuItem) => {
      menuItem.addEventListener("mouseenter", (e) => {
        e.preventDefault();
        showMenu(menuItem);
        activatedOnEnter = true;
      });
      menuItem.addEventListener("touchend", (e) => {
        e.preventDefault();
        showMenu(menuItem);
      });
    },
  );
  performForEach(document.getElementsByClassName("submenu"), (menu) => {
    menu.addEventListener("mouseleave", () => {
      if (activatedOnEnter) {
        menu.classList.remove("active");
        activatedOnEnter = false;
      }
    });
  });
};

const installAccordionAutoScroll = () => {
  performForEach(
    document.getElementsByClassName("accordion-button"),
    (accordionButton) => {
      accordionButton.addEventListener("click", () => {
        const justCollapsed = accordionButton.classList.contains("collapsed");
        if (!justCollapsed) {
          setTimeout(() => {
            scrollElementToOffset(accordionButton.parentElement, 60);
          }, 200);
        }
      });
    },
  );
};

const installProductHovers = () => {
  // js-hover-handler js-hover-sensor js-hovered js-unhovered
  performForEach(
    document.getElementsByClassName("js-hover-handler"),
    (hoverHandler) => {
      performForEach(
        hoverHandler.getElementsByClassName("js-hover-sensor"),
        (hoverSensor) => {
          hoverSensor.addEventListener("mouseenter", () => {
            performForEach(
              hoverHandler.getElementsByClassName("js-hovered"),
              (elem) => {
                elem.style.display = "block";
              },
            );
            performForEach(
              hoverHandler.getElementsByClassName("js-unhovered"),
              (elem) => {
                elem.style.display = "none";
              },
            );
          });
          hoverSensor.addEventListener("mouseleave", () => {
            performForEach(
              hoverHandler.getElementsByClassName("js-hovered"),
              (elem) => {
                elem.style.display = "none";
              },
            );
            performForEach(
              hoverHandler.getElementsByClassName("js-unhovered"),
              (elem) => {
                elem.style.display = "block";
              },
            );
          });
        },
      );
    },
  );
};

const installHideExpandButtons = () => {
  performForEach(document.getElementsByClassName("js-collapse"), (section) => {
    section.addEventListener("show.bs.collapse", () => {
      performForEach(
        section.parentNode.getElementsByClassName("expand-section"),
        (expandSection) => {
          expandSection.style.display = "none";
        },
      );
    });
  });
};

const installSubtitleHandlers = () => {
  const sh = document.getElementById("subheading-text");
  const sb = document.getElementById("subheading-expander");
  if (sh && sb) {
    console.log("handler call", sh.offsetHeight);
    if (sh.offsetHeight > 30 && !sh.classList.contains("truncated")) {
      sh.classList.add("truncated");
      sb.style.display = "inline";
    } else {
      sb.style.display = "none";
    }
  }
};

const installSubtitleExpandHandlers = () => {
  const sh = document.getElementById("subheading-text");
  const sb = document.getElementById("subheading-expander");
  if (sb && sh) {
    sb.addEventListener("click", () => {
      sh.classList.remove("truncated");
      sb.style.display = "none";
    });
  }
};

const installExpandableListHandlers = () => {
  performForEach(
    document.getElementsByClassName("js-expandable-list-item"),
    (listItem) => {
      const buttons = listItem.getElementsByTagName("button");
      for (let button of buttons) {
        button.addEventListener("click", () => {
          listItem.classList.toggle("expanded");
          const target = document.getElementById(`target_${listItem.id}`);
          if (target) {
            if (listItem.classList.contains("expanded")) {
              target.classList.remove("collapse");
            } else {
              target.classList.add("collapse");
            }
          }
        });
      }
    },
  );
};

const installPromoBannerHandlers = () => {
  const promoBanner = document.getElementById("promo-banner");
  if (promoBanner) {
    const cookieName = promoBanner.dataset.cookieName;
    const cookieValue = Cookies.get(cookieName);
    if (cookieValue) {
      promoBanner.classList.add("d-none");
    } else {
      promoBanner.classList.remove("d-none");
    }

    const closeButtons = document.getElementsByClassName(
      "js-promo-banner-cta-dismiss-on-click",
    );
    for (let button of closeButtons) {
      button.addEventListener("click", () => {
        promoBanner.classList.add("d-none");
        Cookies.set(cookieName, 1, { path: "" });
      });
    }
  }
};

const footerAppearanceOptions = {
  root: null,
  threshold: 0.1,
};

const onFooterAppearance = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      document
        .querySelectorAll(".cms-side-banner")
        .forEach((e) => (e.style.visibility = "hidden"));
    } else {
      document
        .querySelectorAll(".cms-side-banner")
        .forEach((e) => (e.style.visibility = "visible"));
    }
  });
};

const footerObserver = new IntersectionObserver(
  onFooterAppearance,
  footerAppearanceOptions,
);

const installSideBannerHandlers = () => {
  const elements = document.querySelector(".secure");
  footerObserver.observe(elements);
};

const installHandlers = () => {
  document.addEventListener("DOMContentLoaded", () => {
    installPlayVideoButtons();
    installTopBarClose();
    updateTopBarVisibility();
    installTopMenuHandlers();
    installProductClickHandlers();
    installMenuHandlers();
    installAccordionAutoScroll();
    installProductHovers();
    reportKlaviyoContext();
    installHideExpandButtons();
    installSubtitleHandlers();
    installSubtitleExpandHandlers();
    installExpandableListHandlers();
    installPromoBannerHandlers();
    installSideBannerHandlers();
  });
};

export default installHandlers;
